import React, { useEffect, useState, useRef } from "react";
import Up from "../../../../../public/icons/micro/chevron-up.svg";
import Down from "../../../../../public/icons/micro/chevron-down.svg";

export default function Searcher({ setSearchValue, finders,  currentMatch, totalMatchCount }) {
  const [isHidden, setIsHidden] = useState(true)
  const inputRef = useRef(null);
  const { prev, next } = finders

  const onKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "f") {
      e.preventDefault();
      setIsHidden(false);
      inputRef.current?.focus()
    }

    if (isHidden && e.key === "Escape") {
      e.preventDefault();
      setIsHidden(true);
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  const cssClasses = [
    // common classes
    ..."absolute right-0 z-50 shadow-sm rounded-sm bg-white flex items-center gap-1 p-4 transition-transform".split(" "),
    // mutually exclusive classes
    ...(isHidden && ["-translate-y-full", "pointer-events-none", "opacity-0"] || ["pointer-events-auto", "opacity-100"]),
  ]

  return (
    <div className={cssClasses.join(" ")}>
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          autoFocus
          className="pr-8!"
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && next()}
        />
        <span className="absolute right-1 top-1/2 -translate-y-1/2 text-xs">{`${currentMatch}/${totalMatchCount}`}</span>
      </div>
      <button className="button button-secondary button-sm" onClick={prev}>
        <Up className="w-5" />
      </button>
      <button className="button button-secondary button-sm" onClick={next}>
        <Down className="w-5" />
      </button>
    </div>
  );
}
