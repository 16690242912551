import CommonsController from "./commons_controller";
import { BarChartStacked } from "gobierto-vizzs";
import { timeFormatDate, isDataTotalEmpty, getQuarter, sortingTooltipFn } from "../../helpers/vizzs_report";

/**
* This controller is for data parsing. The bar chart stacked
* is rendered via the npm-package gobierto-vizzs.
* Add this data attribute to load the data.
* Add it to the element where you define the controller:
    @data-attribute: data-visualizations--evolution-csv-endpoint-value="url"

  (Optional) Add this data attribute where the chart will be rendered:
    @data-attribute: data-config="<%= config.as_json %>"

* @markup-example:

  <% config = [ "contract_type", "month", "absolute" ] %>

  <div
    data-controller="visualizations--evolution"
    data-visualizations--evolution-csv-endpoint-value="data"
  >
    <select
      data-action="visualizations--evolution#selectType"
      data-visualizations--evolution-target="typeSelector"
    >
      <option value="process_type"><%= Tender.human_attribute_name(:process_type) %></option>
      <option value="number_of_proposals"><%= Tender.human_attribute_name(:number_of_proposals) %></option>

    </select>
    <div class="tabs-filters" data-action="click->visualizations--evolution#selectDate">
      <button class="tab-item" data-visualizations--evolution-target="dateSelector" value="month" aria-checked="true">
        <%= Tender.human_attribute_name(:month) %>
      </button>
      <button class="tab-item" data-visualizations--evolution-target="dateSelector" value="quarter">
        <%= Tender.human_attribute_name(:quarter) %>
      </button>
      <button class="tab-item" data-visualizations--evolution-target="dateSelector" value="year">
        <%= Tender.human_attribute_name(:year) %>
      </button>
    </div>

    <div class="tabs-filters" data-action="click->visualizations--evolution#selectRatio">
      <button class="tab-item" data-visualizations--evolution-target="ratioSelector" value="absolute" aria-checked="true">#</button>
      <button class="tab-item" data-visualizations--evolution-target="ratioSelector" value="count">€</button>
      <button class="tab-item" data-visualizations--evolution-target="ratioSelector" value="percentage">%</button>
    </div>

    <div data-visualizations--evolution-target="chart" data-config="<%= evolution_contract_type_month.as_json %>"></div>
    <div style="display: none;">No data</div>
  </div>
**/

export default class extends CommonsController {
  selectType({ target }) {
    if (this.currentType === target.value) return null

    this.currentType = target.value;
    this._update({ typeSelector: this.currentType })
  }

  selectDate({ target }) {
    if (this.currentDate === target.value) return null

    this.currentDate = target.value;

    this.dateSelectorTargets.forEach((elem) =>
      elem === target ? target.setAttribute("aria-checked", true) : elem.removeAttribute("aria-checked")
    );

    this._update({ dateSelector: this.currentDate })
  }

  selectRatio({ target }) {
    if (this.currentRatio === target.value) return null

    this.currentRatio = target.value;

    this.ratioSelectorTargets.forEach((elem) =>
      elem === target ? target.setAttribute("aria-checked", true) : elem.removeAttribute("aria-checked")
    );

    this._update({ ratioSelector: this.currentRatio })
  }

  _loadChart(element, data) {
    const { config } = element.dataset

    const typeSelector = config ? JSON.parse(config)[0] : this.getType();
    const dateSelector = config ? JSON.parse(config)[1] : this.getDate();
    const ratioSelector = config ? JSON.parse(config)[2] : this.getRatio();

    const {
      parsedData,
      categories,
      series,
      tickValues,
      countProp,
      sortStack
    } = this._helper(data, { typeSelector, dateSelector, ratioSelector })

    // Shows the "no data" indicator
    if (isDataTotalEmpty(parsedData)) {
      element.nextElementSibling.style.display = null
      return null
    }

    // Do not pass the "data" to the chart to avoid the DOM blocked
    const chart = new BarChartStacked(element, [], {
      x: dateSelector,
      y: typeSelector,
      count: countProp,
      showLegend: true,
      tooltip: this._tooltip,
      xTickFormat: d => timeFormatDate("year")(new Date(d)),
      ratio: ratioSelector,
      series,
      categories,
      xTickValues: tickValues,
      sortStack
    });

    return  [chart, parsedData]
  }

  _update({ typeSelector = this.currentType, dateSelector = this.currentDate, ratioSelector = this.currentRatio }) {
    const {
      parsedData,
      categories,
      series,
      tickValues,
      countProp,
      sortStack
    } = this._helper(this.initialData, { typeSelector, dateSelector, ratioSelector })

    // Instead of recreate the full chart, we use the setters to update it
    this.visualization.setX(dateSelector)
    this.visualization.setY(typeSelector)
    this.visualization.setRatio(ratioSelector)
    this.visualization.setCount(countProp)
    this.visualization.setSortStack(sortStack)
    this.visualization.setCategories(categories)
    this.visualization.setSeries(series)
    this.visualization.setXTickValues(tickValues)
    this.visualization.setData(parsedData)
  }

  _tooltip(d) {
    const __data = [...d.data[1].entries()].map(([key, values]) => ({
      name: key,
      count: values.length,
      sum: values.reduce((acc, item) => acc + item.initial_amount_no_taxes, 0),
    }));

    // apply the proper sorting method regarding the typeSelector
    const data = sortingTooltipFn(this.yAxisProp)(__data)

    const totals = {
      name: I18n.charts.bar_chart_evolution_distribution.total,
      sum: data.reduce((acc, { sum }) => acc + sum, 0),
      count: data.reduce((acc, { count }) => acc + count, 0),
      header: true,
    };

    const sortFilteredData = [totals, ...data]
      .map(({ name, sum, count, header = false }) => {
        const tdHeader = `<span class="text-tiny text-black font-bold inline-block align-middle">${name}</span>`;
        const tdRegular = `<span style="background-color: ${this.scaleColor(
          name
        )}" class="tooltip-barchart-stacked-grid-key-color inline-block align-middle rounded-sm"></span>
      <span class="text-tiny text-black inline-block align-middle">${name}</span>`;
        const tdSum = `<span class="text-tiny text-black">${new Intl.NumberFormat(undefined, {
          style: "currency",
          currency: "EUR",
        }).format(sum)}</span>`;
        const tdCount = `<span class="text-tiny text-black">${count}</span>`;

        return `
      <tr>
        <td>${header ? tdHeader : tdRegular}</td>
        <td class="text-right">${tdCount}</td>
        <td class="text-right">${tdSum}</td>
      </tr>`;
      })
      .join(" ");

    const date = timeFormatDate(this.xAxisProp)(new Date(d.data[0]));

    return `
      <span class="font-bold">${date}</span>
      <div class="items-list-container">
        <table class="items-list align-top-cells">
          <thead>
            <tr>
              <th></th>
              <th style="text-align: right">${I18n.charts.bar_chart_evolution_distribution.number_of_contracts}</th>
              <th style="text-align: right">${I18n.charts.bar_chart_evolution_distribution.sum}</th>
            </tr>
          </thead>
          <tbody>
            ${sortFilteredData}
          </tbody>
        </table>
      </div>
    `;
  }

  _parseData(data, { typeSelector, dateSelector, oldestAllowedDate }) {
    return [...data.reduce((acc, { submission_date, initial_amount_no_taxes = 0, ...rest }) => {
      const value = acc.get(submission_date)
      const sum = +initial_amount_no_taxes || 0

      if (value) {
        acc.set(submission_date, {
          ...value,
          initial_amount_no_taxes: value.initial_amount_no_taxes + sum
        })
        return acc
      }

      // Ignore the oldest received data
      if (new Date(oldestAllowedDate) > new Date(submission_date)) return acc

      const [year, month] = submission_date.split("-")

      acc.set(submission_date, {
        year,
        month: `${year}-${month}`,
        quarter: getQuarter(submission_date),
        submission_date: submission_date,
        initial_amount_no_taxes: sum,
        selected_date: dateSelector,
        selected_type: typeSelector,
        ...rest,
      })

      return acc;
    }, new Map()).values()];
  }
}
