/**
 * A Stimulus controller that automatically adjusts textarea height based on content.
 * Extends stimulus-textarea-autogrow to provide custom minimum height behavior.
 *
 * @example
 * <textarea data-controller="autogrow"></textarea>
 *
 * @extends TextareaAutogrow
 */
import TextareaAutogrow from 'stimulus-textarea-autogrow'

export default class extends TextareaAutogrow {
  /**
   * Adjusts the textarea height based on its content.
   * Sets a minimum height of 70px while allowing growth based on content.
   * @private
   */
  autogrow () {
    this.element.style.height = 'auto'
    let height = Math.max(70, this.element.scrollHeight)
    this.element.style.height = `${height}px`
  }
}
