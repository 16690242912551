import { Controller } from "@hotwired/stimulus"

// - This controller is used when we want a select to alter other element/s in the DOM (disabling or hidding them)
//
// Usage
// Targets:
// - select: The select input that will control the others
// - disableElement: One or more inputs that will be enabled/disable when the select changes.
// - hideElement: One or more inputs that will be displayed/hidden when the select changes.
//
// Sample usage in app/views/internal_contracts/shared/form/_extensions.html.erb

export default class extends Controller {
  static targets = [ "select", "disableElement", "hideElement"]
  static values = {
    enableOptions: String
  }

  connect(){
    this._handleCurrentStatus();
  }

  toggle(event) {
    this._handleCurrentStatus();
  }

  // private

  _handleCurrentStatus(){
    this._handleDisableElements();
    this._handleHideElements();
  }

  _handleDisableElements(){
    if (this._hasEnabledOptionValue()) {
      this.disableElementTargets.forEach(function(element){
        element.removeAttribute("disabled");
      })
    } else {
      this.disableElementTargets.forEach(function(element){
        element.disabled = true;
      });
    }
  }

  _handleHideElements(){
    if (this._hasEnabledOptionValue()) {
      this.hideElementTargets.forEach(function(element){
        element.classList.remove("hidden!");
      })
    } else {
      this.hideElementTargets.forEach(function(element){
        element.classList.add("hidden!");
      });
    }
  }

  _hasEnabledOptionValue(){
    return this.enableOptionsValue.split(",").includes(this.selectTarget.value)
  }
}
