import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dateField", "dateFieldValue", "contractType", "dateFieldValueContainer", "taskTemplateGroup",
    "taskable", "newObjectiveFormalizationDate", "messageContainer"]
  static values = {
    turboRefreshTaskTemplateGroupUrl: String,
    turboRefreshMessageUrl: String,
    turboUpdateProjectUrl: String,
    dueDateProjectsName: String,
    dueDateInternalContractsName: String,
  }

  connect(){
    this.refreshMessage();
  }

  triggerTaskableChanges(event) {
    // Request to update task template group field
    const currentTaskable = event.currentTarget.value;
    const body = { "tasks_group": {"taskable": currentTaskable} };

    this._makeRequest(this.turboRefreshTaskTemplateGroupUrlValue, JSON.stringify(body));

    // Change name of the due date option.
    // !!! This is legacy, but left in case it's enabled for internal contracts in the future
    const dueDateOption = document.querySelector("#tasks_group_date_field option[value='objective_formalization_date']");
    if (this.taskableTarget.value.startsWith("project")) {
      dueDateOption.innerHTML = this.dueDateProjectsNameValue
    } else {
      dueDateOption.innerHTML = this.dueDateInternalContractsNameValue
    }

    // If it's not a project, we'll select start_date manually and leave it as read only
    if (this.taskableTarget.value.startsWith("project")) {
      dueDateOption.classList.remove("hidden!")
      this.refreshMessage();
    } else {
      this.dateFieldTarget.value = 'start_date'
      dueDateOption.classList.add("hidden!")
    }
  }

  triggerDateFieldChanges(event){
    // Update message information
    this.refreshMessage();

    // If due date is selected we'll hide the date_field_value_container
    const dateFieldChildren = this.dateFieldValueContainerTarget.children;
    for (let item of dateFieldChildren) {
      if (event.target.value == 'start_date') {
        item.classList.remove("hidden!")
      } else {
        item.classList.add("hidden!")
      }
    }

  }

  refreshMessage(){
    if (this.dateFieldTarget.value !== "" &&
      this.dateFieldValueTarget.value !== "" &&
      this.taskableTarget.value.startsWith("project") &&
      this.taskableTarget.value !== "" &&
      this.taskTemplateGroupTarget.value !== "" ) {
      const body = {
        "tasks_group": {
          "date_field": this.dateFieldTarget.value,
          "contract_type": this.contractTypeTarget.value,
          "date_field_value": this.dateFieldValueTarget.value,
          "task_template_group_id": this.taskTemplateGroupTarget.value,
          "taskable": this.taskableTarget.value,
        }
      };

      this._makeRequest(this.turboRefreshMessageUrlValue, JSON.stringify(body));
    } else {
      // We remove the message just in case it's visible and it shouldn't
      this.messageContainerTarget.innerHTML=''
    }
  }

  updateProjectDate(event){
    event.preventDefault();

    if (this.dateFieldTarget.value !== "" &&
      this.dateFieldValueTarget.value !== "" &&
      this.taskableTarget.value.startsWith("project") &&
      this.taskableTarget.value !== "" &&
      this.taskTemplateGroupTarget.value !== "" ) {
      const body = {
        "tasks_group": {
          "date_field": this.dateFieldTarget.value,
          "contract_type": this.contractTypeTarget.value,
          "date_field_value": this.dateFieldValueTarget.value,
          "task_template_group_id": this.taskTemplateGroupTarget.value,
          "taskable": this.taskableTarget.value,
        },
        "project": {
          "objective_formalization_date": this.newObjectiveFormalizationDateTarget.value
        }
      };

      this._makeRequest(this.turboUpdateProjectUrlValue, JSON.stringify(body));
    }
  }

  taskTemplateGroupTargetConnected(target){
    this.refreshMessage()
  }

  // private

  _makeRequest(url, body){
    const headers = this._getRequestHeaders();

    fetch(url, {
      method: "POST",
      headers: headers,
      body: body
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  _getRequestHeaders(){
    let csrfToken;

    if (document.querySelector("meta[name='csrf-token']")) {
      csrfToken = document.querySelector("meta[name='csrf-token']").content
    }

    return {
      Accept: "text/vnd.turbo-stream.html",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    };
  }

}
