import { select } from "d3-selection";
import { scaleLinear, scaleSqrt, scaleTime, scaleOrdinal, scaleLog } from "d3-scale";
import { extent } from "d3-array";
import { axisBottom, axisLeft } from "d3-axis";
import { format } from "d3-format";
import "d3-transition";
import { tooltipPositionOffseted } from "../helpers"

export default class Scatterplot {
  constructor(container, data, options = {}) {
    this.container = container;
    this.margin = { top: 100, bottom: 20, left: 40, right: 20, ...options.margin };
    this.labels = {
      LOG: I18n.log,
      LINEAR: I18n.linear,
      SCALE: I18n.scale,
    }
    this.circlesOpacity = {
      min: 0.1,
      max: 1,
      default: 0.5
    }
    this.scales = {
      log: scaleLog,
      linear: scaleLinear
    }

    // main properties to display
    this.xAxisProp = options.x || "x";
    this.yAxisProp = options.y || "y";
    this.zAxisProp = options.z || "z";
    this.colorAxisProp = options.color;

    this.PALETTE = options.palette || Array.from({ length: 10 }, (_, i) => `var(--gv-color-${i + 1})`);
    this.zAxisTitle = options.zTitle;
    this.tooltip = options.tooltip || this.defaultTooltip;

    // initial scale
    this.currentScaleY = this.scales.linear

    // chart size
    this.getDimensions();
    // static elements (do not redraw)
    this.setupElements();

    if (data.length) {
      this.setData(data);
    }

    window.addEventListener("resize", this.resizeListener.bind(this));
  }

  getDimensions() {
    const { width, height } = this.container.getBoundingClientRect();
    this.width = width - this.margin.left - this.margin.right;
    this.height = height ? height - this.margin.top - this.margin.bottom : this.width / (3 / 1);
  }

  setupElements() {
    this.svg = select(this.container)
      .attr("class", "relative")
      .append("svg");

    this.g = this.svg.append("g").attr("transform", `translate(${this.margin.left} ${this.margin.top})`);

    this.g.append("g").attr("class", "axis axis-x");
    this.g.append("g").attr("class", "axis axis-y");

    this.legendContainer = this.svg
      .append("g")
      .attr("class", "legend")
      .append("foreignObject")

    this.tooltipContainer = select(this.container)
      .append("div")
      .attr("class", "shadow-sm rounded-sm p-4 absolute bg-white z-10 opacity-0 text-sm");
  }

  build() {
    this.setScales();

    this.svg.attr(
      "viewBox",
      `0 0 ${this.width + this.margin.left + this.margin.right} ${this.height + this.margin.top + this.margin.bottom}`
    );

    this.legendContainer
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.margin.top)
      .call((node) => this.legend(node))

    this.g
      .select(".axis-x")
      .attr("transform", `translate(0 ${this.height})`)
      .call(axisBottom(this.scaleX).tickSize(0))
      .call((g) => g.selectAll(".tick text").filter(d => d.getMonth() === 0).classed("font-bold", true))
      .call((g) => g.selectAll(".tick text").filter(d => d.getMonth() !== 0).classed("opacity-50", true))
      .call((g) => g.select(".domain").classed("text-blue-300", true))
      .call((g) =>
        g.attr("font-family", null).attr("font-size", null).classed("text-xs text-black/50", true)
      );

    this.g
      .select(".axis-y")
      .call((g) =>
        g.transition().call(axisLeft(this.scaleY).tickSizeOuter(0).tickSizeInner(-this.width).tickFormat(format("~s")))
      )
      .call((g) => g.select(".domain").classed("text-blue-300", true))
      .call(
        (g) =>
          this.currentScaleY === this.scales.log &&
          g
            .selectAll(".tick")
            // remove those values whose log10 is not an integer
            .filter((d) => Math.log10(d) % 1 !== 0)
            .remove()
      )
      .call((g) =>
        g
          .selectAll(".tick line")
          .classed("text-gray-100", true)
          .filter((d) => d === 0)
          .remove()
      )
      .call((g) =>
        g.attr("font-family", null).attr("font-size", null).classed("text-xs text-black/50", true)
      );

    this.g
      .selectAll("circle")
      .data(this.data)
      .join("circle")
      .call(g => g
        .transition()
        .attr("cx", (d) => this.scaleX(d[this.xAxisProp]))
        .attr("cy", (d) => this.scaleY(d[this.yAxisProp]))
        .attr("r", (d) => this.scaleZ(d[this.zAxisProp]))
        .attr("fill", (d) => this.scaleColor(d[this.colorAxisProp]))
        .attr("fill-opacity", 0.5)
      )
      .on("pointerout", this.onPointerOut.bind(this))
      .on("pointermove", this.onPointerMove.bind(this))
  }

  setData(data) {
    this.rawData = data;
    this.data = this.parse(data);

    this.autodetectScale()
    this.build();
  }

  parse(data) {
    // 1. remove those elements with no X axis data
    // 2. enforces the datatypes:
    //    - X axis is Date
    //    - Y axis properties are Numbers
    return data
      .reduce(
        (acc, d) => [
          ...acc,
          ...(!!d[this.xAxisProp]
            ? [
                {
                  ...d,
                  [this.xAxisProp]: new Date(d[this.xAxisProp]),
                  [this.yAxisProp]: Number(d[this.yAxisProp]),
                },
              ]
            : []),
        ],
        []
      )
      .sort((a, b) => (a[this.xAxisProp] > b[this.xAxisProp] ? 1 : -1));
  }

  setScales() {
    this.scaleX = scaleTime()
      .domain(extent(this.data, (d) => d[this.xAxisProp]))
      .range([0, this.width]);

    this.scaleY = this.currentScaleY()
      .domain(extent(this.data.filter(d => d[this.yAxisProp] > 0), (d) => d[this.yAxisProp]))
      .range([this.height, 0])
      .clamp(true)
      .nice();

    this.scaleZ = scaleSqrt()
      .domain(extent(this.data, (d) => d[this.zAxisProp]))
      .range([5, 20]);

    this.scaleColor = scaleOrdinal()
      .domain(this.colorAxisProp ? Array.from(new Set(this.data.map((d) => d[this.colorAxisProp]))) : [])
      .range(this.PALETTE);
  }

  autodetectScale() {
    const [yMin, yMax] = extent(this.data, (d) => d[this.yAxisProp])
    // apply log scale when the data extends more than two orders of magnitude
    const shouldScaleLog = Math.log10(yMax) - Math.log10(yMin) > 2
    this.currentScaleY = shouldScaleLog ? this.scales.log : this.scales.linear
  }

  onPointerMove(e, d) {
    const tooltip = this.tooltipContainer.html(this.tooltip(d));
    const [xo, yo] = tooltipPositionOffseted(e, tooltip.node());

    this.g.selectAll("circle")
      .filter((x) => x[this.colorAxisProp] === d[this.colorAxisProp])
      .call((g) => g.transition().duration(400).style("fill-opacity", this.circlesOpacity.max))

    this.g.selectAll("circle")
      .filter((x) => x[this.colorAxisProp] !== d[this.colorAxisProp])
      .call((g) => g.transition().duration(400).style("fill-opacity", this.circlesOpacity.min))

    this.legendContainer
      .selectAll(".legend-item")
      .filter(({ text }) => d[this.colorAxisProp] !== text)
      .call((g) => g.transition().duration(400).style("opacity", this.circlesOpacity.default))

    tooltip
      .style("top", `${yo}px`)
      .style("left", `${xo}px`)
      .style("width", "fit-content")
      .style("opacity", 1)
      .style("max-width", `${this.width * 0.6}px`)
      .style("pointer-events", "auto")
      .call((t) => t.transition().duration(400).style("opacity", 1))
      // mouseenter cancels any previous transition on the tooltip element, which is the same as onPointerOut (this.tooltipContainer)
      .on("pointerenter", () => tooltip.transition().duration(0))
      .on("pointerleave", () => tooltip.transition().style("pointer-events", "none").duration(400).style("opacity", 0));
  }

  onPointerOut() {
    this.g
      .selectAll("circle")
      .transition()
      .duration(400)
      .style("fill-opacity", this.circlesOpacity.default);

    this.legendContainer
      .selectAll(".legend-item")
      .call((g) => g.transition().duration(400).style("opacity", null))

    this.tooltipContainer
      .call((g) => g.transition().delay(1000).duration(400).style("opacity", 0))
  }

  defaultTooltip(d) {
    return [
      `<div class="flex space-x-4">
        <span>${d[this.xAxisProp]?.toLocaleDateString()}</span>
        <span>${d[this.yAxisProp]?.toLocaleString(undefined, { style: "currency", currency: "EUR" })}</span>
        <span>${d[this.zAxisProp]?.toLocaleString(undefined, { style: "percent" })}</span>
      </div>`,
    ].join("");
  }

  legend(node) {
    const [min, max] = this.scaleZ.domain()
    const [colorRange, colorDomain] = [this.scaleColor.range(), this.scaleColor.domain()]

    const container = node
      .selectAll("div")
      .data([1])
      .join("xhtml:div")
      .attr("class", "flex flex-row-reverse flex-wrap-reverse justify-between h-full py-3");

    const flex = container
      .selectAll("div")
      .data([
        { ix: 1, classNames: "flex odd:justify-end space-x-4 items-center w-1/4" },
        { ix: 2, classNames: "flex flex-wrap -mx-4 -my-1 odd:justify-end w-3/4 items-center" },
        { ix: 3, classNames: "flex odd:justify-end space-x-4 items-start w-1/4" },
      ])
      .join("xhtml:div")
      .attr("class", ({ classNames }) => classNames);

    // first element
    flex
      .filter(({ ix }) => ix === 1)
      .selectAll("div")
      .data([
        this.zAxisTitle && { text: this.zAxisTitle, spanClassNames: "opacity-50" },
        { color: colorRange[0], text: min, classNames: "w-2 h-2" },
        { color: colorRange[0], text: max },
      ].filter(Boolean))
      .join((enter) => {
        const div = enter.append("xhtml:div");
        div
          .append("span")
          .attr("class", ({ spanClassNames }) => `${spanClassNames} text-xs whitespace-nowrap`)
          .text(({ text }) => text.toLocaleString());

        div
          .filter(({ color }) => !!color)
          .append("i")
          .attr("class", ({ classNames = "w-7 h-7" }) => `${classNames} rounded-full opacity-50`)
          .style("background-color", ({ color }) => color);
        return div;
      })
      .attr("class", "flex items-center space-x-1");

    // second element
    flex
      .filter(({ ix }) => ix === 2)
      .selectAll("div")
      .data(colorDomain.map((d) => ({ color: this.scaleColor(d), text: d })))
      .join((enter) => {
        const div = enter.append("xhtml:div").attr("class", "legend-item flex items-center space-x-1 px-4 py-1");
        div
          .append("i")
          .attr("class", ({ classNames = "w-4 h-4" }) => `${classNames} shrink-0`)
          .style("background-color", ({ color }) => color);
        div
          .append("span")
          .attr("class", "text-xs whitespace-nowrap")
          .text(({ text }) => text.toLocaleString());
        return div;
      })
      .on("pointerout", this.onLegendPointerOut.bind(this))
      .on("pointermove", this.onLegendPointerMove.bind(this))

    // third element
    flex
      .filter(({ ix }) => ix === 3)
      .selectAll("div")
      .data([
        {
          type: "span",
          text: this.labels.SCALE,
          active: false,
          classNames: "text-black pb-1 opacity-50 text-xs",
          handleClick: () => {},
        },
        {
          type: "button",
          text: this.labels.LOG,
          active: this.currentScaleY === this.scales.log,
          classNames: "cursor-pointer text-black pb-1 border-b-4 hover:border-blue-900 text-xs",
          handleClick: () => {
            this.currentScaleY = this.scales.log;
            this.build();
          },
        },
        {
          type: "button",
          text: this.labels.LINEAR,
          active: this.currentScaleY === this.scales.linear,
          classNames: "cursor-pointer text-black pb-1 border-b-4 hover:border-blue-900 text-xs",
          handleClick: () => {
            this.currentScaleY = this.scales.linear;
            this.build();
          },
        },
      ])
      .join((enter) => enter.append(({ type }) => document.createElement(type)))
      .text(({ text }) => text)
      .attr("class", ({ classNames }) => classNames)
      .classed("border-blue-900", ({ active }) => !!active)
      .on("click", (e, { handleClick }) => handleClick());

    return node
  }

  onLegendPointerMove(_, { text }) {
    this.g.selectAll("circle")
      .filter((x) => x[this.colorAxisProp] === text)
      .call((g) => g.transition().duration(400).style("fill-opacity", this.circlesOpacity.max))

    this.g.selectAll("circle")
      .filter((x) => x[this.colorAxisProp] !== text)
      .call((g) => g.transition().duration(400).style("fill-opacity", this.circlesOpacity.min))

    this.legendContainer
      .selectAll(".legend-item")
      .filter(({ text: legendItem }) => legendItem !== text)
      .call((g) => g.transition().duration(400).style("opacity", this.circlesOpacity.min))
  }

  onLegendPointerOut() {
    this.g
      .selectAll("circle")
      .transition()
      .duration(400)
      .style("fill-opacity", this.circlesOpacity.default);

    this.legendContainer
      .selectAll(".legend-item")
      .call((g) => g.transition().duration(400).style("opacity", null))
  }

  resizeListener() {
    this.getDimensions();
    this.build();
  }
}
