import { select } from "d3-selection";
import { scaleLinear, scaleTime, scalePow } from "d3-scale";
import { extent, mean, median } from "d3-array";
import { axisBottom } from "d3-axis";
import { timeYear } from "d3-time";
import { line } from "d3-shape";
import { forceSimulation, forceCollide, forceX } from "d3-force";
import { isoParse } from "d3-time-format";
import "d3-transition";
import { tooltipPositionOffseted } from "../helpers"

export default class Barchart {
  constructor(container, data, options = {}) {
    this.container = container;
    this.labels = {
      MAX: I18n.max,
      MIN: I18n.min,
      MEAN: I18n.mean,
      MEDIAN: I18n.median,
      OLDER: I18n.older,
      NEWER: I18n.newer,
      HIGHER: I18n.higher,
      LOWER: I18n.lower,
    }

    this.tooltip = options.tooltip || this.defaultTooltip;
    this.legend = options.legend || this.defaultLegend;
    this.margin = { top: 30, bottom: 80, left: 0, right: 200, ...options.margin };

    // main properties to display
    this.xAxisProp = options.x || "x";
    this.yAxisProp = options.y || "y";
    this.zAxisProp = options.z || "z";

    this.rootTitle = options.rootTitle || "root";
    this.legendTitle = options.legendTitle;
    this.tooltipTitleProp = options.tooltipTitleProp;
    this.tooltipUrlProp = options.tooltipUrlProp;
    this.yAxisTitle = options.yTitle;
    this.BAR_WIDTH = options.barWidth || 5;

    // chart size
    this.getDimensions();
    // static elements (do not redraw)
    this.setupElements();

    if (data.length) {
      this.setData(data);
    }
  }

  getDimensions() {
    const { width } = this.container.getBoundingClientRect();
    this.width = width - this.margin.left - this.margin.right;
    this.height = this.width / (4 / 1);
  }

  setupElements() {
    this.svg = select(this.container).attr("class", "relative").append("svg");
    this.g = this.svg.append("g").attr("transform", `translate(${this.margin.left} ${this.margin.top})`);
    this.g.append("g").attr("class", "axis axis-x");

    this.g
      .append("rect")
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("class", "text-blue/5")
      .attr("fill", "currentColor");

    this.header = this.svg
      .append("foreignObject")
      .attr("height", this.margin.top)
      .attr("width", this.width)
      .append("xhtml:div")
      .attr("class", "flex justify-between");

    this.tooltipContainer = select(this.container)
      .append("div")
      .attr("class", "shadow-sm rounded-sm p-4 absolute bg-white z-10 opacity-0");
  }

  build() {
    this.setScales();

    this.svg.attr(
      "viewBox",
      `0 0 ${this.width + this.margin.left + this.margin.right} ${this.height + this.margin.top + this.margin.bottom}`
    );

    this.header
      .selectAll("div")
      .data([this.rootTitle, this.legend])
      .join("xhtml:div")
      .html((tpl) => (typeof tpl === "function" ? tpl.call(this) : tpl));

    if (this.isDate(this.xAxisProp)) {
      this.g.select(".axis-x").attr("transform", `translate(0 ${this.height})`).call(this.xAxis.bind(this));
    }

    this.g
      .selectAll("rect.bar")
      .data(this.data)
      .join("rect")
      .attr("width", this.BAR_WIDTH)
      .attr("height", (d) => this.height - this.scaleY(d[this.yAxisProp]))
      .attr("x", (d) => this.scaleX(d[this.xAxisProp]))
      .attr("y", (d) => this.scaleY(d[this.yAxisProp]))
      .attr("fill", "currentColor")
      .attr("fill-opacity", (d) => this.scaleZ(d[this.zAxisProp]))
      .attr("class", "bar text-blue")
      .on("mouseover", this.onMouseOver.bind(this))
      .on("mouseout", this.onMouseOut.bind(this));

    const [xMin, xMax] = extent(this.data, (d) => d[this.xAxisProp]);
    const [yMin, yMax] = extent(this.data, (d) => d[this.yAxisProp]);
    const xMean = mean(this.data, (d) => d[this.xAxisProp]);
    const xMedian = median(this.data, (d) => d[this.xAxisProp]);
    const yMean = mean(this.data, (d) => d[this.yAxisProp]);

    const values = this.isDate(this.xAxisProp)
      ? []
      : [
          [xMin, this.labels.MIN],
          [xMax, this.labels.MAX],
          [
            xMean,
            this.labels.MEAN,
            { "stroke-width": 6, class: "text-gobyellow/50" },
          ],
          [
            xMedian,
            this.labels.MEDIAN,
            { "stroke-width": 6, class: "text-gobpink/50" },
          ],
        ];

    this.g
      .selectAll("g.x-mark.force")
      .data(values)
      .join((enter) => this.xAxisCustomTick(enter))
      .each((d, i, n) => (d.collide = n[i].getBoundingClientRect().width * 0.5))
      .attr("class", "x-mark force text-black-700");

    forceSimulation(values)
      .force("x", forceX((d) => this.scaleX(d[0])).strength(1))
      .force("collide", forceCollide(d => d.collide))
      .on("tick", () => {
        values.forEach((e) => {
          e.fy = this.height + this.margin.bottom * 0.5;
        });

        this.g
          .selectAll("g.x-mark.force text")
          .attr("transform", (d) => `translate(${d.x} ${this.height + this.margin.bottom * 0.5})`);

        this.g.selectAll("g.x-mark.force path").attr("d", (d) =>
          line()([
            [this.scaleX(d[0]), this.height],
            [this.scaleX(d[0]), this.height + this.margin.bottom * 0.15],
            [d.x, this.height + this.margin.bottom * 0.15],
            [d.x, this.height + this.margin.bottom * 0.3],
          ])
        );
      });

    this.g
      .selectAll("g.y-mark")
      .data([
        [yMin, this.labels.MIN],
        [yMax, this.labels.MAX],
        [yMean, this.labels.MEAN],
      ])
      .join((enter) => {
        const g = enter.append("g");
        g.append("line")
          .attr("x1", 0)
          .attr("x2", this.width + this.margin.right * 0.2)
          .attr("y1", 0)
          .attr("y2", 0)
          .attr("stroke", "currentColor")
          .attr("stroke-dasharray", "3 2");
        g.append("text")
          .text((d) => d[1])
          .attr("x", this.width + this.margin.right * 0.3)
          .attr("dominant-baseline", "middle");
        g.append("text")
          .text((d) => d[0].toLocaleString(undefined, { style: "percent" }))
          .attr("x", this.width + this.margin.right * 0.9)
          .attr("dominant-baseline", "middle")
          .attr("text-anchor", "end");
        return g;
      })
      .attr("transform", (d) => `translate(0 ${this.scaleY(d[0])})`)
      .attr("class", "y-mark");

    this.g
      .selectAll("text.label")
      .data([this.yAxisTitle])
      .join("text")
      .attr("x", this.width + this.margin.right * 0.3)
      .attr("y", this.margin.top * -0.5)
      .attr("class", "label font-bold")
      .text((d) => d);
  }

  async setData(data) {
    this.rawData = data;
    this.data = this.parse(data);

    this.build();
  }

  parse(data) {
    // 1. remove those elements with no X axis data
    // 2. enforces the datatypes:
    //    - X axis is Number or Date
    //    - Y axis is Number
    //    - Z axis is Number or Date
    return data
      .reduce((acc, d) => {
        return [
          ...acc,
          // https://2ality.com/2017/04/conditional-literal-entries.html
          ...(!!d[this.xAxisProp] && !!d[this.zAxisProp]
            ? [
                {
                  ...d,
                  [this.xAxisProp]:
                    // when variable is string, check for date: if it's not, then parse as number
                    typeof d[this.xAxisProp] === "string"
                      ? isoParse(`${d[this.xAxisProp]}T00:00:00.000Z`) || +d[this.xAxisProp]
                      : d[this.xAxisProp],
                  [this.yAxisProp]: +d[this.yAxisProp],
                  [this.zAxisProp]:
                    typeof d[this.zAxisProp] === "string"
                      // appending the Time, solves a Chrome compatibility issue
                      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#compatibility_notes
                      ? isoParse(`${d[this.zAxisProp]}T00:00:00.000Z`) || +d[this.zAxisProp]
                      : d[this.zAxisProp],
                },
              ]
            : []),
        ];
      }, [])
      .sort((a, b) => (a[this.xAxisProp] > b[this.xAxisProp] ? 1 : -1));
  }

  setScales() {
    this.scaleX = (this.isDate(this.xAxisProp) ? scaleTime() : scalePow().exponent(0.1))
      .domain(extent(this.data, (d) => d[this.xAxisProp]))
      .range([this.width * 0.05, this.width * 0.95]);

    this.scaleY = scaleLinear().domain([0, 1]).range([0, this.height]);

    this.scaleZ = (this.isDate(this.zAxisProp) ? scaleTime() : scalePow().exponent(0.1))
      .domain(extent(this.data, (d) => d[this.zAxisProp]))
      .range([0.1, 1]);
  }

  isDate(prop) {
    if (!this.isDateProp || this.isDateProp[prop] === undefined) {
      this.isDateProp = { ...this.isDateProp, [prop]: this.data.every((d) => d[prop] instanceof Date)}
    }
    return this.isDateProp[prop];
  }

  xAxis(g) {
    g.call(
      axisBottom(this.scaleX)
        .tickSize(0)
        .tickPadding(this.margin.bottom * 0.25)
        .ticks(timeYear.every(1))
    );

    // remove baseline
    g.select(".domain").remove();

    // remove default formats
    g.attr("font-family", null).attr("font-size", null).attr("class", "font-bold");
  }

  xAxisCustomTick(enter) {
    const g = enter.append("g")

    const text = g
      .append("text")
      .attr("fill", "currentColor")
      .attr("text-anchor", "middle").attr("dominant-baseline", "middle")
      .attr("transform", (d) => `translate(${this.scaleX(d[0])} ${this.height + this.margin.bottom * 0.5})`);

    text
      .append("tspan")
      .text(d => d[0].toLocaleString(undefined, { style: "currency", currency: "EUR" }))
      .attr("x", 0)
      .attr("class", "font-bold");
    text
      .append("tspan")
      .text(d => d[1])
      .attr("dy", "1.5em")
      .attr("x", 0);

    g.append("path")
      .attr("fill", "none")
      .attr("stroke", d => d[2] && "currentColor")
      .attr("stroke-width", d => d[2]?.["stroke-width"])
      .attr("class", d => d[2]?.["class"])

    return g
  }

  onMouseOver(e, d) {
    const tooltip = this.tooltipContainer.html(this.tooltip(d));
    const [x, y] = tooltipPositionOffseted(e, tooltip.node());

    tooltip
      .style("top", `${Math.max(0, Math.min(this.height, y))}px`)
      .style("left", `${Math.max(0, Math.min(this.width, x))}px`)
      .style("width", "fit-content")
      .style("max-width", `${this.width * 0.6}px`)
      .style("pointer-events", "auto")
      .call((t) => t.transition().duration(400).style("opacity", 1))
      // mouseenter cancels any previous transition on the tooltip element, which is the same as onMouseOut (this.tooltipContainer)
      .on("mouseenter", () => tooltip.transition().duration(0))
      .on("mouseleave", () => tooltip.transition().style("pointer-events", "none").duration(400).style("opacity", 0));
  }

  onMouseOut() {
    this.tooltipContainer.transition().delay(1000).duration(400).style("opacity", 0);
  }

  defaultLegend() {
    const [min, max] = this.scaleZ.domain();
    const items = [
      [min, this.isDate(this.zAxisProp) ? this.labels.OLDER : this.labels.LOWER],
      [max, this.isDate(this.zAxisProp) ? this.labels.NEWER : this.labels.HIGHER],
    ];

    const itemTpl = ([v, k]) =>
      `<span class="flex items-center"><i class="bg-blue w-3 h-3 mr-2 inline-block" style="opacity:${this.scaleZ(
        v
      )}"></i> ${k}</span>`;
    const title = (this.legendTitle && `<label>${this.legendTitle}</label>`) || "";
    return `<div class="flex justify-between space-x-4">${title}${items.map(itemTpl).join("")}</div>`;
  }

  defaultTooltip(d) {
    const x = this.format(this.xAxisProp, d);
    const z = this.format(this.zAxisProp, d);
    return [
      this.tooltipUrlProp && this.tooltipTitleProp
        ? `<a href="${d[this.tooltipUrlProp]}" target="_blank">${d[this.tooltipTitleProp]}</a>`
        : this.tooltipTitleProp
        ? `<p>${d[this.tooltipTitleProp]}</p>`
        : null,
      `<div class="flex space-x-4">
        <span>${x}</span>
        <span>${d[this.yAxisProp]?.toLocaleString(undefined, { style: "percent" })}</span>
        <span>${z}</span>
      </div>`,
    ].filter(Boolean).join("");
  }

  format(prop, d) {
    return this.isDate(prop)
      ? d[prop]?.toLocaleDateString()
      : d[prop]?.toLocaleString(undefined, { style: "currency", currency: "EUR" });
  }
}
