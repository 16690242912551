import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["taskableField", "phaseField", "phaseContainer"]
  static values = {phasesUrl: String}

  connect(){
    this._handleCurrentSelection(false);
  }

  updatePhases(event){
    this._handleCurrentSelection();
  }

  // private

  _handleCurrentSelection(updatePhases=true){
    if (updatePhases) {
      this._removePhaseOptions();
    }

    if (this._isProjectSelected()) {
      if (updatePhases) {
        this._fetchAndSetProjectPhases();
      }
      this._showAndEnablePhases()
    } else if (this._isContractSelected()) {
      this._hideAndDisablePhases()
    } else {
      this._hideAndDisablePhases()
    }
  }

  _removePhaseOptions(){
    while (this.phaseFieldTarget.options.length) {
      this.phaseFieldTarget.options[0].remove();
    }
  }

  _fetchAndSetProjectPhases(){
    const projectId = this.taskableFieldTarget.value.split("#")[1];
    const fetchUrl = `${this.phasesUrlValue }?project_id=${projectId}`

    fetch(fetchUrl, {
      headers: new Headers({
        "Accept": "application/json",
      }),
    })
    .then((response) => response.json())
    .then((json) => this._setProjectsPhases(json))
  }

  _setProjectsPhases(phases){
    phases.forEach((element) => {
      this.phaseFieldTarget.options[this.phaseFieldTarget.options.length] = new Option(element['text'], element['id']);
    });
  }

  _hideAndDisablePhases(){
    this.phaseContainerTarget.classList.add("hidden!");
    this.phaseFieldTarget.setAttribute("disabled", "disabled")
  }

  _showAndEnablePhases(){
    this.phaseContainerTarget.classList.remove("hidden!");
    this.phaseFieldTarget.removeAttribute("disabled")
  }

  _isProjectSelected(){
    return this.taskableFieldTarget.value.startsWith("project")
  }

  _isContractSelected(){
    return this.taskableFieldTarget.value.startsWith("internal_contract")
  }
}
