import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import Rails from "@rails/ujs";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "../react/pdf-highlighter/PdfHighlighter.jsx";

export default class extends Controller {
  static classes = ["destroy"]

  static targets = [
    "container",
    "createForm",
    "createPosition",
    "createContent",
    "createComment",
    "destroyForm",
    "reactRoot",
    "turboElement"
  ];

  static values = {
    user: Number,
    docId: Number,
    load: {
      type: Boolean,
      default: true
    },
    interactive: {
      type: Boolean,
      default: true
    },
    hasAccessToCatalogClauses: {
      type: Boolean,
      default: false
    },
    catalogClausesUrl: String
  };

  async connect() {
    this.fetchUrl = `/documents/${this.docIdValue}/annotations.json`;

    // documents path
    [this.baseUrl] = this.fetchUrl.split("/annotations")

    // initialize react container
    this.root = createRoot(this.reactRootTarget);

    if (this.loadValue) {
      this.loadReact();
    }

    // receive events from aside bar
    document.addEventListener("turbo:submit-end", () => this.loadReact());
  }

  async create({ detail }) {
    const { position, content } = detail;
    const data = await fetch(this.fetchUrl).then(r => r.json());
    const sameAnnotation = data.some(({ content: { text } }) => text === detail.content.text)
    if(sameAnnotation) {
      return;
    }

    this.createPositionTarget.value = JSON.stringify(position);
    this.createContentTarget.value = JSON.stringify(content);

    Rails.fire(this.createFormTarget, "submit");
    // back to list
    Turbo.navigator.history.replace({ href: this.baseUrl })
  }

  destroyFromSidebar(event) {
    // Simulates the original destroy event
    this.destroy({ detail: { id: event.params.annotation }})
  }

  destroy({ detail }) {
    const { id } = detail
    const annotation = this.element.querySelector(`[data-annotation="${id}"]`)

    annotation.classList.add(...this.destroyClasses)

    // Schedule the "confirm" prompt in a separate thread,
    // otherwise, the prompt will block the CSS animation
    setTimeout(() => {
      annotation.classList.remove(...this.destroyClasses)

      if (!confirm(I18n.are_you_sure)) {
        return null
      }

      this.destroyFormTarget.action = `${this.destroyFormTarget.action}/${id}`;
      Rails.fire(this.destroyFormTarget, "submit");
      // back to list
      Turbo.navigator.history.replace({ href: this.baseUrl })
    }, 100);
  }

  comment({ detail }) {
    const { position, content, id, document_id } = detail;
    const { location: { pathname } } = window;
    const [,,documentIdUrl,,idUrl] = pathname.split('/') || []
    const documentIdUrlNumber = Number(documentIdUrl)
    const idUrlNumber = Number(idUrl)
    /*
    If you hover a text highlighted and click on comment, the PDF
    is reloaded and scrolled to the top and then back to the same position.
    To avoid this behavior, I get the annotation id from the URL and
    I compare it with the id annotation. If it is the same got a return;
    */
    if(id !== undefined && id === idUrlNumber) {
      this._scrollToComment()
      return;
    }

    if (id && (document_id !== documentIdUrlNumber)) {
      Turbo.visit(`${this.baseUrl}/annotations/${id}`)
      /* PDF can contain many annotations, to avoid full reloads,
      if we click on the comment button, we need to check if the document
      is the same as the document_id. In this case only updates the
      SRC="" of the turbo-frame with the location. And, it updates
      the history to set the URL with the id of annotation.*/
    } else if (id && (document_id === documentIdUrlNumber)) {
      Turbo.navigator.history.replace({ href: `${this.baseUrl}/annotations/${id}` })
      this.turboElementTarget.src = location
    } else {
      this.createPositionTarget.value = JSON.stringify(position);
      this.createContentTarget.value = JSON.stringify(content);
      this.createCommentTarget.value = true;

      // to trigger the scroll to the new comment
      // we need wait for the new ID
      this.shouldTriggerPushState = true
      Rails.fire(this.createFormTarget, "submit");
    }
  }

  _scrollToComment() {
    document.getElementById('comment_body').focus();
    window.scrollTo(0, ((window.innerHeight - this.containerTarget.getBoundingClientRect().height) * 2))
  }

  copyUrl() {
    const el = document.createElement('textarea');
    el.value = location.href;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    const alert = document.createElement('div');
    alert.className = "flash-message notice absolute mx-auto z-50 h-14 w-max animate-slide-y-in inset-20 shadow-2xl ring-3 ring-inset"
    alert.innerHTML = I18n.url
    document.body.appendChild(alert)

    setTimeout(() => {
      document.body.removeChild(alert)
    }, 1500);
  }

  setUrl({ currentTarget }) {
    const { annotation } = currentTarget.dataset
    history.pushState({ annotation }, null, `${this.baseUrl}/annotations/${annotation}`)
    window.dispatchEvent(new Event("push-state"))
  }

  resetUrl() {
    history.pushState(null, null, this.baseUrl)
    window.dispatchEvent(new Event("push-state"))
  }

  async loadReact() {
    const data = await fetch(this.fetchUrl).then(r => r.json());

    // only when new comment
    if (this.shouldTriggerPushState) {
      const { id } = data[data.length - 1] // last element will be the new one
      history.pushState({ annotation: id }, null, `${this.baseUrl}/annotations/${id}`)
      window.dispatchEvent(new Event("push-state"))
      this.shouldTriggerPushState = false
    }

    const { dataset } = this.reactRootTarget;
    dataset.hasAccessToCatalogClauses = this.hasAccessToCatalogClausesValue;
    this.root.render(<App {...dataset} annotations={data} interactive={this.interactiveValue} baseUrl={this.baseUrl} />);
  }

  zoomIn() {
    let { currentScale = 1 } = window.PdfViewer?.viewer || {}

    window.PdfViewer.viewer.currentScale =
      +currentScale < 3 ? +currentScale + 0.2 : 3;
  }

  zoomOut() {
    let { currentScale = 1 } = window.PdfViewer?.viewer || {}

    window.PdfViewer.viewer.currentScale =
      +currentScale > 0.5 ? +currentScale - 0.2 : 0.5;
  }

  zoomAuto() {
    // currentScaleValue is a string, differs from currentScale
    window.PdfViewer.viewer.currentScaleValue = 'auto';
  }

  async createCatalogClause({ detail }) {
    const { content } = detail;
    const url = new URL(this.catalogClausesUrlValue, window.location.origin);
    url.searchParams.set('base_model', content.text);
    
    window.open(url.toString(), '_blank');
  }
}
