/**
 * Conversation Menu Controller
 *
 * This controller manages the conversation menu interface, including
 * menu navigation, panel switching, and item selection. It works in
 * conjunction with the input-menu controller but focuses on the
 * conversation-specific behaviors.
 *
 * Usage:
 * ```html
 * <div data-controller="conversation-menu" data-conversation-menu-hidden-class="hidden">
 *   <div role="menu" data-conversation-menu-target="menu">
 *     <div
 *       aria-role="menuitem"
 *       data-conversation-menu-target="menuItem"
 *       data-category="category-name"
 *       data-action="
 *         mouseover->conversation-menu#handleMouseover
 *         click->conversation-menu#handleMenuItemClick
 *       "
 *     >
 *       Menu Item
 *     </div>
 *   </div>
 *
 *   <div
 *     id="option-category-name"
 *     data-conversation-menu-target="optionPanel"
 *   >
 *     <button data-action="conversation-menu#backToMenu">
 *       Back
 *     </button>
 *
 *     <div aria-role="menuitem" data-action="click->conversation-menu#handleSubItemClick">
 *       Sub Item
 *     </div>
 *   </div>
 * </div>
 * ```
 *
 * Targets:
 * - menu: The main menu container
 * - menuItem: Main menu items
 * - optionPanel: Category-specific panels
 * - input: The input field for the conversation
 *
 * Classes:
 * - hidden: Class used to hide/show elements
 */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "menuItem", "optionPanel", "input"]
  static classes = ["hidden"]

  connect() {
    this._initializePanels()
    this._setupInputBlurHandler()
    this._setDefaultOptionAndLabel()
  }

  // Public methods - called directly by Stimulus actions
  handleInputFocus() {
    this._updateInputContainer('add')
  }

  handleInputBlur() {
    this._updateInputContainer('remove')
  }

  handleMenuItemClick(event) {
    const category = event.currentTarget.dataset.category
    if (!category) return

    const panel = this.optionPanelTargets.find(p => p.id === `option-${category}`)
    if (!panel) return

    this._handlePanelChange(panel)
  }

  handleSubItemClick(event) {
    if (this.hasInputTarget) {
      this._updateFromSubItem(event)
    }
  }

  backToMenu() {
    this.optionPanelTargets.forEach(panel => panel.classList.add(this.hiddenClass))
    this.optionPanelTargets.forEach(panel => panel.querySelector('[aria-role="menuitem"]').setAttribute('aria-selected', 'false'))
    this.menuTarget.classList.remove(this.hiddenClass)
  }

  handleMainMenuMouseover(event) {
    this._updateAriaSelected(this.menuItemTargets, event.currentTarget)
  }

  handleSubMenuMouseover(event) {
    const panel = event.currentTarget.closest('[role="menu"]')
    if (!panel) return

    const menuItems = Array.from(panel.querySelectorAll('[aria-role="menuitem"]'))
    this._updateAriaSelected(menuItems, event.currentTarget)
  }

  handleSubMenuMouseout(event) {
    const panel = event.currentTarget.closest('[role="menu"]')
    if (!panel) return

    const menuItems = Array.from(panel.querySelectorAll('[aria-role="menuitem"]'))
    this._updateAriaSelected(menuItems, event.currentTarget, false)
  }

  handleEnter(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()

      const items = this.optionPanelTargets
      const selectedPanel = this.optionPanelTargets.find(panel => panel.querySelectorAll('[aria-role="menuitem"][aria-selected="true"]').length)
      if (selectedPanel) {
        const selectedItem = selectedPanel.querySelector('[aria-role="menuitem"][aria-selected="true"]')

        if (!this.inputTarget.value) {
          selectedItem.click()
        }
      }
      event.target.form.requestSubmit()
    }
  }

  // Private methods
  _initializePanels() {
    this.optionPanelTargets.forEach(panel => panel.classList.add(this.hiddenClass))
  }

  _setupInputBlurHandler() {
    if (this.hasInputTarget) {
      this.inputTarget.addEventListener('blur-sm', () => this.handleInputBlur())
    }
  }

  _setDefaultOptionAndLabel() {
    const firstPanel = this.optionPanelTargets[0]
    if (firstPanel) {
      document.getElementById('assistant-router-option').value = firstPanel.dataset.optionId
      document.getElementById('assistant-router-label').textContent = firstPanel.dataset.optionLabel
    }
  }

  _updateInputContainer(action) {
    const container = document.getElementById('assistant-input-container')
    container.classList[action]('focus-ring')
    container.setAttribute('aria-expanded', action === 'add' ? 'true' : 'false')
  }

  _handlePanelChange(panel) {
    if (this.hasInputTarget) {
      this.inputTarget.value = ''
      this.inputTarget.focus()
    }

    this._updateRouterValues(panel)
    this._showPanel(panel)
  }

  _updateRouterValues(panel) {
    document.getElementById('assistant-router-option').value = panel.dataset.optionId
    document.getElementById('assistant-router-label').textContent = panel.dataset.optionLabel
  }

  _showPanel(panel) {
    this.menuTarget.classList.add(this.hiddenClass)
    this.optionPanelTargets.forEach(p => p.classList.add(this.hiddenClass))
    panel.classList.remove(this.hiddenClass)
  }

  _updateFromSubItem(event) {
    const panel = event.currentTarget.closest('[data-conversation-menu-target="optionPanel"]')
    this._updateRouterValues(panel)

    this.inputTarget.value = event.currentTarget.textContent.trim()
    this.inputTarget.focus()

    this._setCaretAtEnd()
  }

  _setCaretAtEnd() {
    const range = document.createRange()
    const selection = window.getSelection()
    range.selectNodeContents(this.inputTarget)
    range.collapse(false)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  _updateAriaSelected(items, selectedItem, selected = true) {
    items.forEach(item => item.setAttribute('aria-selected', 'false'))
    selectedItem.setAttribute('aria-selected', selected ? 'true' : 'false')
  }

  hasSubpanelOpen() {
    return this.optionPanelTargets.some(panel =>
      !panel.classList.contains("hidden")
    )
  }
}
