import { Controller } from "@hotwired/stimulus"

/**
 * Discardable Items Controller
 * 
 * This controller manages a list of items that can be temporarily hidden (discarded) from a table.
 * Discarded items are persisted in the backend and updated via Turbo Streams.
 * 
 * Usage:
 * 1. Add the controller to your table container:
 *    <div data-controller="discardable-items" data-discardable-items-table-name-value="unique_table_name">
 * 
 * 2. Add the message container for discarded items count:
 *    <div data-discardable-items-target="hiddenItemsMessage">
 *      <span data-discardable-items-target="counter"></span>
 *    </div>
 * 
 * 3. For each row that can be discarded:
 *    <tr data-discardable-items-target="row" data-item-id="unique_id" data-item-type="ItemType">
 * 
 * 4. Add discard and restore buttons:
 *    <button data-discardable-items-target="discardButton" data-action="click->discardable-items#discardItem">
 *    <button data-discardable-items-target="restoreButton" data-action="click->discardable-items#restoreItem">
 * 
 * Features:
 * - Discard items: Hide items from the table via backend request
 * - Show/Hide discarded: Toggle visibility of discarded items with different styling
 * - Restore items: Remove items from the discarded list via backend request
 * - Clear all: Remove all items from the discarded list via backend request
 * 
 * Targets:
 * - row: Table rows that can be discarded
 * - discardButton: Button to discard an item
 * - restoreButton: Button to restore a discarded item
 * - hiddenItemsMessage: Container for the discarded items count message
 * - counter: Element to display the count of discarded items
 * 
 * Values:
 * - showDiscarded: Boolean to control the visibility of discarded items
 */

export default class extends Controller {
  static targets = ["row", "discardButton", "restoreButton", "hiddenItemsMessage", "counter"]
  static values = {
    showDiscarded: { type: Boolean, default: false }
  }

  connect() {
    this._updateItemsMessageVisibilityAndCounter()
  }

  toggleDiscardedItems(event) {
    const isChecked = event.target.checked
    this.showDiscardedValue = isChecked
    
    // Update visibility of all discarded rows
    this.rowTargets
      .filter(row => row.dataset.discarded === "true")
      .forEach(row => {
        if (isChecked) {
          row.classList.remove('hidden')
        } else {
          row.classList.add('hidden')
        }
      })
  }

  discardItem(event) {
    event.preventDefault()
    event.stopPropagation()
    
    const row = event.currentTarget.closest('[data-discardable-items-target="row"]')
    if (!row) return
    
    fetch('/discarded_items', {
      method: 'POST',
      headers: this._getFetchHeaders(),
      body: JSON.stringify({
        discarded_item: {
          item_id: row.dataset.itemId,
          item_type: row.dataset.itemType
        }
      })
    })
    .then(response => this._handleResponse(response, row, true))
  }

  restoreItem(event) {
    event.preventDefault()
    event.stopPropagation()
    
    const row = event.currentTarget.closest('[data-discardable-items-target="row"]')
    if (!row) return
    
    fetch('/discarded_items', {
      method: 'DELETE',
      headers: this._getFetchHeaders(),
      body: JSON.stringify({
        item_id: row.dataset.itemId,
        item_type: row.dataset.itemType
      })
    })
    .then(response => this._handleResponse(response, row, false))
  }

  clearDiscardedItems(event) {
    event.preventDefault()
    
    if (!confirm(I18n.are_you_sure_clear_discarded_items)) {
      return
    }
    
    const discardedItems = this.rowTargets
      .filter(row => row.dataset.discarded === "true")
      .map(row => ({
        item_id: row.dataset.itemId,
        item_type: row.dataset.itemType
      }))

    if (discardedItems.length === 0) return
    
    fetch('/discarded_items/destroy_all', {
      method: 'DELETE',
      headers: this._getFetchHeaders(),
      body: JSON.stringify({
        discarded_items: discardedItems
      })
    })
    .then(response => {
      if (response.ok) {
        this.rowTargets
          .filter(row => row.dataset.discarded === "true")
          .forEach(row => this._updateRowState(row, false))
        
        this._updateItemsMessageVisibilityAndCounter()
      } else {
        console.error('Error clearing items')
      }
    })
  }

  // private

  _updateRowState(row, isDiscarded) {
    row.dataset.discarded = isDiscarded.toString()
    const discardButton = row.querySelector('[data-discardable-items-target="discardButton"]')
    const restoreButton = row.querySelector('[data-discardable-items-target="restoreButton"]')
    
    if (isDiscarded) {
      row.classList.add('discarded-item')
      if (!this.showDiscardedValue) {
        row.classList.add('hidden')
      }

      discardButton.classList.add('hidden')
      restoreButton.classList.remove('hidden')
    } else {
      row.classList.remove('discarded-item', 'hidden')

      discardButton.classList.remove('hidden')
      restoreButton.classList.add('hidden')
    }
  }

  _handleResponse(response, row, isDiscarded) {
    if (response.ok) {
      if (row) {
        this._updateRowState(row, isDiscarded)
      }
      this._updateItemsMessageVisibilityAndCounter()
    } else {
      console.error(`Error ${isDiscarded ? 'discarding' : 'restoring'} item`)
    }
  }

  _updateItemsMessageVisibilityAndCounter() {
    // Count discarded items in the current view
    const discardedItems = this.rowTargets.filter(row => row.dataset.discarded === "true")
    const discardedCount = discardedItems.length

    // Update counter
    if (this.hasCounterTarget) {
      this.counterTarget.textContent = discardedCount
    }

    // Update message visibility
    if (this.hasHiddenItemsMessageTarget) {
      if (discardedCount > 0) {
        this.hiddenItemsMessageTarget.classList.remove('hidden')
      } else {
        this.hiddenItemsMessageTarget.classList.add('hidden')
      }
    }
  }

  _getCSRFToken() {
    const tokenElement = document.querySelector('[name="csrf-token"]')
    return tokenElement ? tokenElement.content : null
  }

  _getFetchHeaders() {
    const headers = {
      'Content-Type': 'application/json'
    }
    
    const token = this._getCSRFToken()
    if (token) {
      headers['X-CSRF-Token'] = token
    }
    
    return headers
  }

} 
